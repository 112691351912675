<template>
  <main>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12" md="6" lg="4">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-success`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Komisi Sudah Ditarik</small></strong>
                <h2>
                  <strong>Rp. {{ formatRupiah(rekapKomisi.komisi_sudah_ditarik) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-warning`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Komisi Proses Ditarik</small></strong>
                <h2>
                  <strong>Rp. {{ formatRupiah(rekapKomisi.komisi_proses_ditarik) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-info`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Saldo Komisi</small></strong>
                <h2>
                  <strong>Rp. {{ formatRupiah(rekapKomisi.saldo_komisi) }}</strong>
                </h2>
              </section>
            </div>
          </b-card>
        </b-col>
        <!-- <b-col sm="12" md="6" lg="4">
          <b-card>
            <div class="d-flex align-items-center justify-content-start">
              <section class="icon">
                <b-avatar :variant="`light-primary`" size="50">
                  <feather-icon size="24" icon="DollarSignIcon" />
                </b-avatar>
              </section>
              <section class="info ml-2">
                <strong class="d-block" style="margin-bottom: 5px"><small>Modal</small></strong>
                <h2><strong>Rp 150.000</strong></h2>
              </section>
            </div>
          </b-card>
        </b-col> -->
      </b-row>
      <b-card-actions action-collapse title="List Rekening">
        <!-- ref="refreshCard"
    action-refresh
    @refresh="refreshStop('refreshCard')" -->
        <b-row>
          <b-col md="12" sm="12" class="my-1">
            <b-button @click.prevent="add" variant="outline-primary"> <feather-icon icon="PlusIcon" class="mr-50" />Tambah Penarikan </b-button>
            <b-modal v-model="showModalTambah" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal" centered title="Form Ajuan">
              <b-form>
                <b-form-group>
                  <label for="rekening">Rekening </label>
                  <v-select :options="listRekening" v-model="form.rekening_id" label="text" :reduce="(option) => option.value"></v-select>
                </b-form-group>
                <b-form-group>
                  <label for="nominal">Nominal</label>
                  <b-form-input type="text" @keyup="doFormatRupiah" v-model="form.nominal" id="nominal" placeholder="Nominal" />
                </b-form-group>
              </b-form>

              <template #modal-footer>
                <section class="d-flex justify-content-end align-items-center">
                  <b-button :disabled="tombolAjukan == false" size="sm" class="mr-1" variant="primary" @click="submit"> Ajukan </b-button>
                  <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
                </section>
              </template>
            </b-modal>
            <b-modal v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary" ok-title="Ubah" cancel-title="Batal" centered title="Form Ubah">
              <b-form>
                <b-form-group>
                  <label for="nama_bank">Nama Bank:</label>
                  <b-form-input v-model="form.nama" id="nama_bank" type="text" placeholder="Nama Bank" />
                </b-form-group>
                <b-form-group>
                  <label for="no_rekening">No. Rekening</label>
                  <b-form-input v-model="form.no_rekening" id="no_rekening" placeholder="No. Rekening" />
                </b-form-group>
                <b-form-group>
                  <label for="reward">Deskripsi</label>
                  <b-form-input v-model="form.deskripsi" id="deskripsi" placeholder="Deskripsi" />
                </b-form-group>
                <b-form-group>
                  <label for="reward">Rekening Utama ? </label>
                  <b-form-checkbox class="ml-2" v-model="form.rekening_utama" switch inline> </b-form-checkbox>
                  <!-- <b-form-input v-model="form.nilai" id="reward" placeholder="Poin" /> -->
                </b-form-group>
              </b-form>

              <template #modal-footer>
                <section class="d-flex justify-content-end align-items-center">
                  <b-button size="sm" class="mr-1" variant="info" @click="submit"> Ubah </b-button>
                  <b-button size="sm" variant="danger" @click="resetForm"> Batal </b-button>
                </section>
              </template>
            </b-modal>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(no)="row">
                <strong class="text-center">
                  {{ row.index + 1 }}
                </strong>
              </template>
              <template #cell(created_at)="row">
                <strong class="text-center">
                  {{ humanDateTime(row.value) }}
                </strong>
              </template>
              <template #cell(nominal)="row">
                <strong class="text-center"> Rp. {{ formatRupiah(row.value) }} </strong>
              </template>
              <template #cell(status)="{ item }">
                <b-badge v-if="item.status == 'selesai'" variant="success">SELESAI</b-badge>
                <b-badge v-else-if="item.status == 'proses'" variant="warning">PROSES</b-badge>
                <b-badge v-else-if="item.status == 'dibatalkan'" variant="danger">DIBATALKAN</b-badge>
                <b-badge v-else variant="secondary">PENDING</b-badge>
              </template>
              <template #cell(actions)="row">
                <b-button v-if="row.item.status == 'pending'" v-b-tooltip.hover.right="'Batalkan'" size="sm" @click="batalkan(row.item)" class="mr-1" variant="gradient-danger"> Batalkan </b-button>
                <b-button v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </b-col>
          <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
            <pre>{{ infoModal.content }}</pre>
          </b-modal>

          <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
          </b-col>
        </b-row>
      </b-card-actions>
    </b-overlay>
  </main>
</template>

<script>
import vSelect from "vue-select";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BCard, BTable, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCard,
    BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      showModalTambah: false,
      showModalEdit: false,
      id: null,
      form: {
        rekening_id: null,
        nominal: null,
      },
      tombolAjukan: true,
      rekapKomisi: {},
      listRekening: [],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "created_at", label: "Tanggal Penarikan", sortable: true },
        { key: "nominal", label: "Nominal", sortable: true },
        { key: "rekening.no_rekening", label: "No. Rekening", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items

    this.getData();
    this.getRekapData();
    this.getRekening();
  },
  methods: {
    doFormatRupiah() {
      this.form.nominal = this.formatRupiah(this.form.nominal);
      // console.log;
      if (this.unFormatRupiah(this.form.nominal) > this.rekapKomisi.dapat_ditarik) {
        this.tombolAjukan = false;
      } else {
        this.tombolAjukan = true;
      }
    },
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Penarikan ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: item.id,
          };
          this.$store
            .dispatch("ajuan/hapus", payload)
            .then(() => {
              this.pesanBerhasilHapus();
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    batalkan(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Penarikan ini akan dibatalkan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: item.id,
          };
          this.$store
            .dispatch("ajuan/batal", payload)
            .then(() => {
              this.pesanBerhasilHapus();
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        rekening_id: null,
        nominal: null,
      };
      this.showModalTambah = false;
      this.showModalEdit = false;
    },
    submit() {
      this.loading = true;
      if (this.form.rekening_id == null || this.form.rekening_id == "") {
        this.loading = false;
        this.pesanGagalSimpan();
        return false;
      }
      let payload = {
        karyawan_id: this.user.karyawan.id,
        type: "profesi",
        nominal: this.unFormatRupiah(this.form.nominal),
        rekening_id: this.form.rekening_id,
      };

      this.$store
        .dispatch("ajuan/save", payload)
        .then(() => {
          this.loading = false;
          this.form = {
            rekening_id: null,
            nominal: null,
          };
          this.showModalTambah = false;
          this.showModalEdit = false;
          this.getData();
          this.getRekapData();
          this.pesanBerhasilSimpan();
        })
        .catch((e) => {
          this.showModalTambah = false;
          this.showModalEdit = false;
          this.loading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Gagal",
                icon: "XIcon",
                text: e.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "bottom-right",
            }
          );
          console.error(e);
        });
    },
    edit(item) {
      this.id = item.id;
      console.log(item);
      this.form = {
        nama: item.nama,
        no_rekening: item.no_rekening,
        deskripsi: item.deskripsi,
        rekening_utama: item.rekening_utama == 1 ? true : false,
      };
      this.showModalEdit = true;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil Mengajukan Penarikan",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      if (this.isCabang || this.isMarketing) {
        var params = {
          karyawan_id: this.user.karyawan.id,
          type: "profesi",
        };
      } else {
        var params = {};
      }
      this.$store
        .dispatch("ajuan/getData", params)
        .then(() => {
          this.items = this.$store.state.ajuan.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    getRekapData() {
      let payload = {
        karyawan_id: this.user.karyawan.id,
        type: "profesi",
      };
      this.$store
        .dispatch("ajuan/getRekap", payload)
        .then((res) => {
          this.rekapKomisi = res;
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    getRekening() {
      let payload = {
        karyawan_id: this.user.karyawan.id,
      };
      this.$store
        .dispatch("rekening/getData", payload)
        .then((res) => {
          let newListRekening = [];
          res.map((item) => {
            newListRekening.push({
              text: item.nama + " (" + item.no_rekening + ")",
              value: item.id,
            });
          });
          this.listRekening = newListRekening;
        })
        .catch((e) => {
          this.displayError(e);
        });
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>./Penarikan.vue
